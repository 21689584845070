// Define your color variables
$color-red: #FF0000;
$color-green: #014421;
$color-blue: #0047ab;
$color-yellow: #ffdf00;
$color-lilac: #C8A2C8;
$color-black: #000000;
$color-grey: #808080;

// Define your size variables
$size-small: 10px;
$size-medium: 20px;
$size-large: 30px;

// Define a mixin for plate styles
@mixin plate-style($color, $weight) {
background: radial-gradient(circle, #666666,$color);
}

// Usage example
.plate {
  &.custom {
        @include plate-style($color-black, 55);
      }
  &.red {
    @include plate-style($color-red, 55);
  }
  &.yellow {
    @include plate-style($color-yellow, 55);
  }
  &.blue {
    @include plate-style($color-blue, 45);
  }
  &.green {
    @include plate-style($color-green, 25);
  }
  &.black {
    @include plate-style($color-black, 10);
  }
  &.grey {
    @include plate-style($color-grey, 5);
  }
  &.lilac {
    @include plate-style($color-lilac, 2.5);
  }
}
