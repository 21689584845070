.App {
  text-align: center;
  background-color:black;
  color:white;
}

.input-container {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
}
.background-banner {
  height: 300px;
   width: 100%;
   background-repeat: no-repeat;
   background-size:contain;
   background-position: center;
  background-image: url('./assets/images/rack-right-banner.jpg');
}
button {
  margin-top: 10px;
}

.plate-list {
  margin-top: 30px;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin-bottom: 5
}

.green-filter {
  filter:  invert(53%) sepia(59%) saturate(3883%) hue-rotate(111deg) brightness(101%) contrast(98%);
}
.red-filter {
  filter: invert(12%) sepia(98%) saturate(7129%) hue-rotate(0deg) brightness(95%) contrast(115%);
}
.blue-filter {
  filter: invert(14%) sepia(85%) saturate(3628%) hue-rotate(209deg) brightness(96%) contrast(101%);
}
.yellow-filter {
  filter:invert(80%) sepia(54%) saturate(528%) hue-rotate(342deg) brightness(105%) contrast(103%)
}
.grey-filter {
  filter: invert(52%) sepia(6%) saturate(8%) hue-rotate(331deg) brightness(95%) contrast(103%);
}
.lilac-filter {
  filter:  invert(88%) sepia(9%) saturate(1613%) hue-rotate(228deg) brightness(81%) contrast(91%)
}