.barbell {
  display: inline-block;
  height: 109px;
  /* left: 50%; */
  /* margin: -54px 0 0 -277px; */
 /* // top: 50%; */
  width: 554px;
}

.plate {
  background: radial-gradient(circle, #666666,#444444);
  border: 2px solid #FFFFFF;
  border-radius: 10%;
  /* //display: inline-block; */
  height: 100px;
  margin: 0 1px;
  width: 25px;
}

.bar {
  background: radial-gradient(#ffffff,#bbbbbb);
  border: 2px solid #FFFFFF;
  /* bottom: 40px; */
  display: inline-block;
  height: 20px;
  margin: 0 1px;
  width: 100%;
}

.collar {
  border: 2px solid #FFFFFF;
  /* bottom: 40px; */
  display: inline-block;
  height: 20px;
  margin: 0 1px;
  width: 25px;
}

.collarLeft {
  background: radial-gradient(at right, #ffffff,#bbbbbb);
}

.collarRight {
  background: radial-gradient(at left, #ffffff,#bbbbbb);
}

  .plate-weight-number {
        color: white;
      
        display: table-cell; vertical-align: middle;
  }